// 关键词页面
import request from '@/utils/request'

// 查询初始化
export function PageInit(id) {
    return request({
      url: "/api/ad/keyword/pageInit",
      method: "get",
      headers:{
        'profileId':id
      }
    })
}

// 查询分页
export function getListPage(params) {
  return request({
    url: "/api/ad/keyword/listPage",
    params,
    method: "get"
  }) 
}

// 新增初始化
export function getAddInit(profileId) {
  return request({
    url: "/api/ad/keyword/addInit",
    headers: {
      "profileId": profileId
    },
    method: "get"
  })
}

// 根据asin 获取商品信息
export function getAsinByAsins(data) {
  return request({
    url: "/api/ad/groups/getAsinByAsins",
    data,
    method: "post"
  })
}

// 查询建议关键词根据Asin
export function getSuggestedKeywordsByAsin(asinList, profileId) {
  return request({
    url: "/api/ad/keyword/listSuggestedKeywordsByAsin/" + asinList,
    method: "get",
    headers: {
      "profileId": profileId
    },
  })
}

// 关键词批量操作
export function BatchKeyword(data, profileId) {
  return request({
    url: "/api/ad/keyword",
    data,
    headers: {
      "profileId": profileId
    },
    method: "put"
  })
}

// 表现明细查询
export function listByKeyword(params) {
  return request({
    url: "/api/ad/keyword/listByKeyword",
    params,
    method: "get"
  })
}

// 根据关键词获取建议竞价
export function getKeywordBidRecommendations(keywordId, profileId) {
  return request({
    url: "/api/ad/keyword/getKeywordBidRecommendations/" + keywordId,
    method: "get",
    headers: {
      "profileId": profileId
    },
  })
}

// 查询已经存在的关键词
export function getListExistKeywords(params) {
  return request({
    url: "/api/ad/keyword/listExistKeywords",
    params,
    method: "get"
  })
}

// 修改否定关键词状态
export function updateNeKeyword(data, profileId) {
  return request({
    url: "/api/ad/keyword/updateNeKeyword",
    data,
    method: "put",
    headers:{
     "profileId":profileId
    }
  })
}

// 查询已经存在的否定关键词
export function getListNegativeKeywords(params) {
  return request({
    url: "/api/ad/keyword/listNegativeKeywords",
    params,
    method: "get"
  })
}

// 新增关键词
export function addKeyword(data, profileId) {
  return request({
    url: "/api/ad/keyword/addKeyword",
    data,
    headers: {
      "profileId": profileId
    },
    method: "post"
  })
}

// 新增否定关键词
export function addNegativeKeyword(data, profileId) {
  return request({
    url: "/api/ad/keyword/addNegativeKeyword",
    data,
    headers: {
      "profileId": profileId
    },
    method: "post"
  })
}

// 修改竞价
export function modifyBid(data, profileId) {
  return request({
    url: "/api/AmzAdCampaigns/updateBid",
    data,
    headers: {
      "profileId": profileId
    },
    method: "put"
  })
}

// 广告设置关注
// 新增关注
export function addFocuson(params) {
  return request({
    url: "/api/cpc/focus/add",
    params,
    method: "get"
  })
}

// 取消关注
export function cancelFocuson(params) {
  return request({
    url: "/api/cpc/focus/cancel",
    params,
    method: "get"
  })
}